import React from "react"
import SEO from "../components/layout/seo"

import Layout from "../components/layout/layout"
import ContactUsPage from "../components/contact-us/contactus"

const About = () => (
  <Layout home={false}>
    <SEO title="Contact Us" />
    <ContactUsPage/>
  </Layout>
)

export default About
