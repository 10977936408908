import React from "react"
import { Link } from "gatsby"

import Carousel from "../../widgets/carousel/carousel"

import "./contactus.scss"

import phoneIcon from "../../images/contact-us/phoneIcon.svg"
import emailIcon from "../../images/contact-us/email.svg"
import locationIcon from "../../images/contact-us/location.svg"

import fbIcon from "../../images/home-banner/facebook.svg"
import linkedinIcon from "../../images/home-banner/linkedin.svg"
import indiamartIcon from "../../images/home-banner/indimart.png"
import instagramIcon from "../../images/home-banner/instagram.svg"
import mediumIcon from "../../images/home-banner/medium.svg"
import twitterIcon from "../../images/home-banner/twitter.svg"

import img1 from "../../images/contact-us/showcase/c1.jpeg"
import img2 from "../../images/contact-us/showcase/c2.jpeg"
import img3 from "../../images/contact-us/showcase/c3.jpeg"
import img4 from "../../images/contact-us/showcase/c4.jpeg"
import img5 from "../../images/contact-us/showcase/c5.jpeg"
import img6 from "../../images/contact-us/showcase/c6.jpeg"
import img7 from "../../images/contact-us/showcase/c7.jpeg"

class ContactUsPage extends React.Component {
  state = {
    name: "",
    email: "",
    message: "",
  }

  validateFormSubmition = () => {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

    if (re.test(String(this.state.email).toLowerCase())) {
      fetch("http://13.127.174.142:5000//api/sendGridTest", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          name: this.state.name,
          email: this.state.email,
          message: this.state.message,
        }),
      }).then(val =>
        alert(
          "Your request has been accepted. We will get back to you shortly."
        )
      )
    } else {
      alert("Please enter a valid e-mail address")
    }
  }

  render() {
    return (
      <>
        {/* Banner */}
        <div className="contact-banner">
          <div className="contact-banner-banner"></div>
          <div className="contact-banner-content">
            <div className="contact-banner-content-heading">
              <b>Contact Us</b>
            </div>
            <div className="contact-banner-content-content">
              <b>Let's Get in Touch</b>
            </div>
          </div>
        </div>
        <div className="contact-content">
          {/* Form */}
          <div className="contact-form-container">
            <div style={{ width: "5rem" }}></div>
            <div className="contact-form-info">
              <div className="contact-form-info-content">
                <div className="contact-form-info-content-heading">
                  LETS GET IN TOUCH
                </div>
                <div className="contact-form-info-content-subheading">
                  Want to know more about us? Send us a message.
                </div>
                <div className="contact-form-info-content-info">
                  <div className="contact-form-info-content-row">
                    <div className="contact-form-info-content-row-icon">
                      <img src={phoneIcon} alt="" />
                    </div>
                    <div className="contact-form-info-content-row-content">
                      +91-9110381150
                    </div>
                  </div>
                  <div className="contact-form-info-content-row">
                    <div className="contact-form-info-content-row-icon">
                      <img src={emailIcon} alt="" />
                    </div>
                    <div className="contact-form-info-content-row-content">
                      <a
                        href="mailto:sales@highstreetlighting.com"
                        style={{ color: "#417DB2", textDecoration: "none" }}
                      >
                        sales@highstreetlighting.com
                      </a>
                    </div>
                  </div>
                  <div className="contact-form-info-content-row">
                    <div className="contact-form-info-content-row-icon">
                      <img src={locationIcon} alt="" />
                    </div>
                    <div className="contact-form-info-content-row-content">
                      Plot No. 10
                      <p>2nd Floor</p>
                      <p>Sector 18, Electronic City</p>
                      <p>Gurugram (India) 122001</p>
                    </div>
                  </div>
                  <div className="contact-form-info-content-row">
                    <div
                      className="home-banner-content home-banner-learn-buttons"
                      style={{ marginLeft: "2rem" }}
                    >
                      <Link
                        to="https://www.facebook.com/highstreetlighting/"
                        target="_blank"
                        className="home-banner-button"
                        style={{ marginLeft: "0" }}
                      >
                        <img src={fbIcon} alt="facebook" />
                      </Link>
                      <Link
                        to="https://www.linkedin.com/company/high-street-lighting-private-limited/about/"
                        target="_blank"
                        className="home-banner-button"
                      >
                        <img src={linkedinIcon} alt="linkedin" />
                      </Link>
                      <Link
                        to="https://www.indiamart.com/highstreetlighting/"
                        target="_blank"
                        className="home-banner-button"
                      >
                        <img src={indiamartIcon} alt="indiamart" />
                      </Link>
                      <Link
                        to="https://instagram.com/high_street_lighting?igshid=i0p8rl1rf5gr"
                        target="_blank"
                        className="home-banner-button"
                      >
                        <img src={instagramIcon} alt="instagram" />
                      </Link>
                      <Link
                        to="https://medium.com/@highstreetlightings"
                        target="_blank"
                        className="home-banner-button"
                      >
                        <img src={mediumIcon} alt="medium" />
                      </Link>
                      <Link
                        to="https://twitter.com/HighStreetLigh1?s=09"
                        target="_blank"
                        className="home-banner-button"
                      >
                        <img src={twitterIcon} alt="medium" />
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="contact-form-form">
              <div className="contact-form-form-content">
                <div className="contact-form-form-name">
                  <input
                    placeholder="NAME"
                    autoComplete="off"
                    onChange={e => {
                      this.setState({
                        name: e.target.value,
                      })
                    }}
                  />
                </div>
                <div className="contact-form-form-email">
                  <input
                    placeholder="EMAIL"
                    autoComplete="off"
                    onChange={e => {
                      this.setState({
                        email: e.target.value,
                      })
                    }}
                  />
                </div>
                <div className="contact-form-form-message">
                  <textarea
                    placeholder="MESSAGE"
                    autoComplete="off"
                    onChange={e => {
                      this.setState({ message: e.target.value })
                    }}
                  />
                </div>
              </div>
              <div className="contact-form-form-button" id="projects">
                <button onClick={this.validateFormSubmition}>
                  SEND MESSAGE
                </button>
              </div>
            </div>
            <div className="contact-form-arrow-thingy"></div>
          </div>
        </div>
        <div style={{ margin: "0 auto" }}>
          <div
            className="about-history-heading"
            style={{ marginTop: "-4rem", minWidth: "800px" }}
          >
            <div className="about-history-heading-container">
              <div className="about-history-heading-lead-lines">
                <div className="about-history-heading-lead-line-top"></div>
                <div className="about-history-heading-lead-line-bottom"></div>
              </div>
              <div className="about-history-heading-text">Our Projects</div>
            </div>
          </div>
          <div
            style={{
              width: "80%",
              height: "500px",
              minWidth: "800px",
              margin: "0 auto",
            }}
          >
            <Carousel>
              <img src={img1} />
              <img src={img2} />
              <img src={img3} />
              <img src={img4} />
              <img src={img5} />
              <img src={img6} />
              <img src={img7} />
            </Carousel>
          </div>
          <div style={{ height: "5rem" }}></div>
        </div>
      </>
    )
  }
}

export default ContactUsPage
